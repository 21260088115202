// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  width: 100%;
  max-width: 600px;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.url-input {
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px;
  outline: none;
  transition: border-color 0.3s ease;
}
.url-input:focus {
  border-color: #007bff;
}

.submit-button {
  padding: 10px 20px;
  font-size: 16px;
  color: black;
  background-color: #f8f82b;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: 0.5s ease;
}
.submit-button:hover {
  box-shadow: 0 0 15px rgba(255, 238, 0, 0.59);
}
.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  color: #dc3545;
  margin-top: 10px;
}

.instruction {
  margin-top: 20px;
  color: #6c757d;
}`, "",{"version":3,"sources":["webpack://./src/pages/ScrapeForm.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,kCAAA;AACF;AACE;EACE,qBAAA;AACJ;;AAGA;EACE,kBAAA;EACA,eAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;EACA,qBAAA;AAAF;AAEE;EACE,4CAAA;AAAJ;AAGE;EACE,yBAAA;EACA,mBAAA;AADJ;;AAKA;EACE,cAAA;EACA,gBAAA;AAFF;;AAKA;EACE,gBAAA;EACA,cAAA;AAFF","sourcesContent":[".form-container {\n  width: 100%;\n  max-width: 600px;\n  text-align: center;\n}\n\nform {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.url-input {\n  padding: 10px 15px;\n  font-size: 16px;\n  border: 1px solid #ccc;\n  border-radius: 25px;\n  outline: none;\n  transition: border-color 0.3s ease;\n\n  &:focus {\n    border-color: #007bff;\n  }\n}\n\n.submit-button {\n  padding: 10px 20px;\n  font-size: 16px;\n  color: black;\n  background-color: #f8f82b;\n  border: none;\n  border-radius: 25px;\n  cursor: pointer;\n  transition: 0.5s ease;\n\n  &:hover {\n    box-shadow: 0 0 15px rgba(255, 238, 0, 0.59);\n  }\n\n  &:disabled {\n    background-color: #cccccc;\n    cursor: not-allowed;\n  }\n}\n\n.error-message {\n  color: #dc3545;\n  margin-top: 10px;\n}\n\n.instruction {\n  margin-top: 20px;\n  color: #6c757d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
